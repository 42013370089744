<template>
  <v-container class="pa-0 white menu-link-list-container">
    <div class="header d-flex flex-column">
      <v-row no-gutters justify="space-between" class="ma-2">
        <img
          class="pa-0 logo ma-2"
          :height="$vuetify.breakpoint.smAndDown ? '42px' : '63'"
          src="/logo/logo_claim.png"
          alt="Logo Zona.eu"
        />
        <v-btn depressed icon class="white pa-0" @click="$emit('close')">
          <v-icon large>$close</v-icon>
        </v-btn>
      </v-row>
      <!-- <h4 class="secondary--text ma-2 d-none d-sm-inline">
        {{ $t("navbar.categoryTitle") }}
      </h4> -->
      <!-- <v-tabs
        v-model="selectedTab"
        class="px-2 mb-4 d-inline d-sm-none"
        hide-slider
        active-class="activeTab"
      >
        <v-tab key="categories">
          <h6 class="text-none">{{ $t("navbar.products") }}</h6>
        </v-tab>
        <v-tab key="info" v-if="$vuetify.breakpoint.xs">
          <h6 class="text-none">{{ $t("navbar.links") }}</h6>
        </v-tab>
      </v-tabs> -->
    </div>

    <div v-if="links">
      <v-expansion-panels
        accordion
        flat
        tile
        :focusable="selectedTab"
        class="px-4"
      >
        <v-expansion-panel v-for="(link, index) in links" :key="index">
          <v-expansion-panel-header>
            <template v-slot:actions>
              <v-icon>$chevronDown</v-icon>
            </template>
            <span class="secondary--text font-weight-bold text-uppercase">{{
              link.name
            }}</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="mt-2">
            <a
              v-for="child in link.templateNavItems"
              :key="child.templateNavItemId"
              :href="child.itemUrl || '#'"
              class="d-block default--text text-decoration-none py-2"
              :class="[child.classStyle, { 'no-evt': index == 2 }]"
              :target="child.target"
              @click.stop.prevent="onLinkClick"
            >
              <span :class="child.classStyle">{{ child.name }}</span>
            </a>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <v-container :fluid="$vuetify.breakpoint.xs">
      <v-divider class="grey w-100 mb-1" />
      <v-row class="py-8">
        <v-col
          cols="12"
          sm="12"
          class="d-flex align-center justify-center pa-2 logo-gap"
          :class="$vuetify.breakpoint.xs ? 'justify-center' : 'justify-end'"
        >
          <div
            v-for="(item, idx) in socialItems"
            :key="idx"
            class="d-flex align-center px-2"
            @click="clicked"
          >
            <a :href="item.url" target="_blank" class="text-decoration-none"
              ><img
                class="d-block"
                :src="`/img_layout/footer/${item.icon}.svg`"
                :alt="`Immagine ${item.icon}`"
              />
            </a>
          </div>
        </v-col>
      </v-row>
      <v-layout
        class="align-center d-inline-flex py-4 primary white--text rounded-sm w-100"
        :column="$vuetify.breakpoint.xs"
      >
        <div
          class="text-caption mx-3 mx-sm-10"
          v-html="$t('footer.copyright')"
        />
      </v-layout>
    </v-container>
    <div class="section default--text last text-center" v-if="isCordova">
      <i18n path="footer.version" tag="span" class="my-1 child pl-4">
        {{ version }}
      </i18n>
    </div>
  </v-container>
</template>
<style lang="scss">
.menu-link-list-container {
  .section {
    border-bottom: 1px solid #e9e9e9;
    padding: 12px 0;
    &:first-of-type {
      padding-top: 0 !important;
    }
  }
  .section.last {
    border-bottom: none;
  }
  .icon-close {
    font-size: 24px !important;
    @media only screen and (max-width: 350px) {
      font-size: 22px;
    }
  }
  .header {
    border-bottom: 1px solid #d5d5d5;
  }
  .v-expansion-panel {
    border-bottom: 1px solid #d5d5d5;
  }
}
</style>
<script>
import clickHandler from "~/mixins/clickHandler";
import downloadDocument from "~/mixins/downloadDocument";

export default {
  mixins: [clickHandler, downloadDocument],
  props: ["links"],
  data() {
    return {
      socialItems: [
        {
          url: "https://wa.me/393371213792/",
          icon: "whatsapp"
        },
        {
          url: "https://www.linkedin.com/company/s-i-d-al-srl/",
          icon: "linkedin"
        },
        {
          url: "https://www.facebook.com/ZONAProfessionalstore",
          icon: "facebook"
        },
        {
          url: "https://www.instagram.com/zona_professionalstore/",
          icon: "instagram"
        },
        {
          url: "https://www.youtube.com/@ZONAProfessionalStore",
          icon: "youtube"
        }
      ],
      selectedTab: null
    };
  },
  computed: {
    version() {
      return global.config.version;
    }
  },
  methods: {
    isSocialLinksColumn(classes) {
      return classes.search("social") > -1;
    },
    isCordovaPlatform(classes) {
      return this.isSocialLinksColumn(classes) && this.cordovaPlatform;
    },
    onLinkClick(e) {
      var url = e.currentTarget.href;
      let fileName = "";
      this.$emit("close");
      this.$nextTick(() => {
        console.log("EVENT:", e);
        if (
          this.$platform_is_cordova &&
          e.currentTarget.href.endsWith(".pdf")
        ) {
          fileName = url.substring(url.lastIndexOf("/") + 1, url.length);
          fileName = fileName.substring(0, fileName.length - 4);
          this.handleReceiptDownload(url, fileName);
        } else if (e.target.className.includes("cookie-widget-trigger")) {
          if (window.Cookiebot) {
            window.Cookiebot.show();
          }
        } else {
          this.clicked(e);
        }
      });
    },
    goToExternalPage(url) {
      if (this.$platform_is_cordova && url.indexOf("http") > -1) {
        //if app open on _system browser
        window.cordova.InAppBrowser.open(url, "_system");
      } else {
        window.open(url, "_blank");
      }
    },
    openCookies() {
      if (window.Cookiebot) {
        window.Cookiebot.show();
      }
    }
  }
};
</script>
